<template>
    <div class="ml-10 mr-10">
        <TitleBar>{{ $t("menu.payments") }}
            <v-btn
                class="mx-2"
                color="secondary"
                fab
                small
                to="/expenses/payments/new"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </TitleBar>

        <List :fields="['paid_at', 'amount', 'currency', 'contact']" api-key="payments"
              api-url="/expenses/payments"></List>

    </div>
</template>

<script>

import List from "../../components/List";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Dashboard",
    components: {TitleBar, List},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.payments"),
                to: "/expenses/payments",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>